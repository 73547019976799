'use strict';

import http from '@/utils/http';

export default {
  pageUrl: '/account/opportunityBind/page',
  marketingUrl: '/marketing/marketingPromoteUser/singlePage?marketingType=2',
  opportunityBindUrl: '/account/opportunityBind/getSelect',

  marketingList: '/marketing/marketingPromoteUser/getSelect?marketingType=2',
  // 获取未绑定线索池接口
  leadPoolList() {
    return http.get('/account/opportunityBind/getNoBindPoolList');
  },

  // 根据ID查询商机绑定信息
  // getOpportunityBindById(code, params) {
  //   return http.get(`/account/opportunityBind/${params}`, { code });
  // },

  // 添加商机绑定表
  addBind(code, data) {
    return http.post('account/opportunityBind/add', data, { code });
  },
  // 自动分发设置
  updateAutoDistribute(code, params) {
    return http.put('/account/opportunityBind/autoDistribute', {}, { params,code });
  },
  delete(code, params) {
    return http.delete('/account/opportunityBind', { params, code });
  },

  bindPromoter(code, params) {
    return http.put('/account/opportunityBind/bind', {},{params, code });
  },

};
