<template>
  <a-modal
    :visible="visible"
    :title="'营销人员列表'"
    :mask-closable="false"
    :width="800"
    forceRender
    centered
    :destroyOnClose="true"
    :ok-button-props="{ style: { display: 'none' } }"
    :cancel-button-props="{ style: { display: 'none' } }"
    @cancel="handleCancelBind"
  >
    <!--       @ok="handleOk"
     -->
    <div>
      <search-form :items="items" @fnSearch="handleFnSearch" />
      <grid
        ref="gridRef"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 700, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
      </grid>
    </div>
  </a-modal>
</template>
<script>
import { reactive, ref, toRefs, watch } from 'vue';
// import { Form } from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import bindApi from '@/api/bind.js';
import { message } from 'ant-design-vue';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    // AForm: Form,
    // AFormItem: Form.Item,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    init: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, context) {
    const gridRef = ref();
    // const required = { required: true, message: '不能为空' };
    const form = reactive({
      id: '',
    });

    const state = reactive({
      search: {},
    });

    watch(
      () => props.init,
      init => {
        form.id = init;
      }
    );

    // const { validateInfos, validate, resetFields } = Form.useForm(form, {
    //   typeName: [required, { max: 15, message: '标题长度不能超过15' }],
    // });

    const handleCancelBind = () => {
      context.emit('cancel', false);
      context.emit('update:visible', false);
      // 表单重置
      // resetFields();
    };

    return {
      ...toRefs(state),
      form,
      gridRef,
      // validateInfos,
      handleCancelBind,
      url: bindApi.marketingUrl,
      gridHeight: document.body.clientHeight - 456,

      items: [
        { key: 'userName', label: '姓名' },
        { key: 'mobileNum', label: '手机号码' },
      ],

      columns: [
        { dataIndex: 'userName', title: '姓名', width: 120 },
        { dataIndex: 'mobileNum', title: '手机号码', width: 120 },
        { dataIndex: 'companyName', title: '公司' },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 60,
          slots: { customRender: 'operation' },
        },
      ],

      options: [
        {
          type: 'bind',
          label: '绑定',
          icon: 'SwapOutlined',
          permission: 'bind: bind',
          fnClick: record => {
            bindApi
              .bindPromoter('', { promoteUserId: record.id, id: form.id })
              .then(() => {
                message.success('操作成功');
                handleCancelBind();
                gridRef.value.refreshGrid();
              });
          },
        },
      ],

      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },

      // 分类列表保存方法调用接口的方法和事件
      handleCancel: () => {
        context.emit('fnOk', '');
      },
    };
  },
};
</script>
<style lang="less" scoped>
.live-group {
  padding: 0 12px 15px 10px;
  display: flex;
  align-items: center;
  .group-input {
    flex: 1;
  }
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
}
.live-group .group-input[data-v-61219ec2] {
  flex: 1;
  padding-bottom: 15px;
}
.flex {
  white-space: pre-wrap;
  word-break: break-word;
  word-wrap: break-word;
}
span.anticon.anticon-minus-circle {
  color: #5979f8 !important;
}
.ant-divider {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: #313d5f;
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum';
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}
</style>
