<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>

        <template #autoDistribute="{ text }">
          <a-tag color="green" v-if="text === true">启用</a-tag>
          <a-tag color="red" v-else-if="text === false">停用</a-tag>
        </template>

        <template #autoScoreLevel="{ text }">
          <span class="score-level" v-if="text">
            {{ autoScoreLevel[text] }}
          </span>
          <span v-else>-</span>
        </template>
      </grid>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    title="添加线索池到商机"
    :width="500"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-form :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
      <a-form-item label="线索池" v-bind="validateInfos.leadPool">
        <a-select
          mode="multiple"
          autoClearSearchValue
          v-model:value="formPool.leadPool"
          placeholder="请选择线索池"
          :filter-option="
            (inputValue, option) => handleFilterOption(inputValue, option, item)
          "
        >
          <a-select-option
            v-for="item in leadPoolList"
            :key="item"
            :value="item.leadPoolId"
          >
            {{ item.leadPoolName }}
          </a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
  <a-modal
    v-model:visible="visibleDistribute"
    title="自动分发设置"
    :width="500"
    centered
    @ok="handleOkDistribute"
    @cancel="handleCancelDistribute"
  >
    <a-form
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      class="form-item"
    >
      <a-form-item
        label="自动分发开关"
        extra="开启后，进入未分发列表的商机即自动分发给商机归属人"
      >
        <a-switch
          v-model:checked="form.autoDistribute"
          checked-children="开"
          un-checked-children="关"
          @change="openChange"
        />
      </a-form-item>
      <!--   disabled   -->
      <a-form-item
        label="自动分发分阶"
        extra="开启自动分发开关才能勾选，自动分发分阶所属的商机"
      >
        <a-checkbox-group
          v-model:value="form.autoScoreLevelArray"
          :disabled="unChecked"
        >
          <a-checkbox :value="form.high">高</a-checkbox>
          <a-checkbox :value="form.middle">中</a-checkbox>
        </a-checkbox-group>
      </a-form-item>
    </a-form>
  </a-modal>
  <marketers-list
    v-model:visible="visibleMarketers"
    :init="marketersItem"
    @cancel="handleCancelBind"
  />
</template>

<script>
import { reactive, ref, toRefs, watch } from 'vue';
import {
  Form,
  Modal,
  Select,
  message,
  Switch,
  Checkbox,
  Tag,
} from 'ant-design-vue';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
import bindApi from '@/api/bind.js';
import MarketersList from './components/marketersList';
// import { useRouter } from 'vue-router';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASwitch: Switch,
    ACheckbox: Checkbox,
    ACheckboxGroup: Checkbox.Group,
    MarketersList,
    ATag: Tag,
  },
  setup() {
    // const formRef = ref();
    const gridRef = ref();
    const required = { required: true, message: '不能为空' };
    const formPool = reactive({
      leadPool: [],
    });
    const form = reactive({
      autoDistribute: '',
      autoScoreLevelArray: [],
      id: '',
      middle: 'middle',
      high: 'high',
      autoScoreLevel: '',
    });
    const state = reactive({
      search: {},
      TagList: [],
      visible: false,
      visibleDistribute: false,
      visibleMarketers: false,
      marketersItem: {},
      leadPoolList: [],
      unChecked: null,
    });

    const { validateInfos, validate, resetFields } = Form.useForm(form, {
      // autoDistribute: [required],
      // autoScoreLevel: [required],
      leadPool: [required],
    });

    // 添加线索池到商机校验
    // const formPoolVali = Form.useForm(formPool, {
    //   leadPool: [required],
    // });

    // 监听分发开关的值
    watch(
      () => form.autoDistribute,
      () => {
        state.unChecked = !form.autoDistribute;
      }
    );

    // 获取未绑定的线索池下拉列表
    const getleadPoolList = () => {
      bindApi.leadPoolList().then(data => {
        state.leadPoolList = data;
      });
    };
    getleadPoolList();

    return {
      gridRef,
      form,
      formPool,
      // formRef,
      required,
      ...toRefs(state),
      validateInfos,
      // formPoolVali,
      url: bindApi.pageUrl,
      gridHeight: document.body.clientHeight - 359,
      autoScoreLevel: {
        high: '高分',
        middle: '中分',
        'high,middle': '高中分',
        'middle,high': '高中分',
      },
      items: [
        {
          key: 'leadPoolId',
          label: '线索池',
          type: 'select',
          valueKey: 'leadPoolId',
          labelKey: 'leadPoolName',
          showSearch: true,
          filterOption: 'label',
          url: bindApi.opportunityBindUrl,
        },
        {
          key: 'promoteUserId',
          label: '绑定人',
          type: 'select',
          valueKey: 'id',
          labelKey: 'userName',
          showSearch: true,
          filterOption: 'label',
          url: bindApi.marketingList,
        },
      ],

      columns: [
        {
          dataIndex: 'leadPoolName',
          title: '线索池',
          width: 220,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteUserName',
          title: '商机绑定人',
          width: 130,
          ellipsis: true,
        },
        // { dataIndex: 'unDistributeCount', title: '未分发商机' },
        {
          dataIndex: 'distributeCount',
          title: '已分发商机',
          width: 130,
          ellipsis: true,
        },
        { dataIndex: 'updateTime', title: '更新时间', width: 180 },
        {
          dataIndex: 'autoScoreLevel',
          title: '自动分发分阶',
          width: 120,
          slots: { customRender: 'autoScoreLevel' },
        },
        {
          dataIndex: 'autoDistribute',
          title: '自动分发',
          slots: { customRender: 'autoDistribute' },
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 140,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'bind:add',
          fnClick: () => {
            state.visible = true;
          },
        },
      ],
      options: [
        {
          type: 'marketers',
          label: '营销人员列表',
          icon: 'UserSwitchOutlined',
          permission: 'bind: marketers',
          fnClick: record => {
            state.visibleMarketers = true;
            state.marketersItem = record.id;
          },
        },
        {
          type: '' + '',
          label: '自动分发设置',
          icon: 'SettingTwoTone',
          permission: 'bind: autoDistribute',
          show: record => {
            if (record.promoteUserName !== null) {
              return true;
            } else {
              return false;
            }
          },

          fnClick: record => {
            form.autoDistribute = record.autoDistribute;
            form.autoScoreLevelArray =
              record.autoScoreLevel && record.autoScoreLevel.split(',');
            form.id = record.id;
            state.visibleDistribute = true;
          },
        },

        {
          type: 'delete',
          permission: 'bind: delete',
          fnClick: ({ id }) => {
            bindApi.delete('', { ids: [id] }).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],

      openChange: value => {
        state.unChecked = !value;
      },
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },

      handleCancelBind: () => {
        gridRef.value.refreshGrid();
      },

      // 自动分发弹窗按钮
      handleCancelDistribute() {
        state.visibleDistribute = false;
        resetFields();
      },

      handleOkDistribute: () => {
        form.autoScoreLevel =
          form.autoScoreLevelArray && form.autoScoreLevelArray.toString();
        if (form.autoDistribute === true && form.autoScoreLevel === null)
          return message.error('分发分阶不能为空');

        if (form.autoDistribute === true && form.autoScoreLevel === '')
          return message.error('分发分阶不能为空');

        let params = {
          autoDistribute: form.autoDistribute,
          autoScoreLevel: form.autoScoreLevel,
          id: form.id,
        };

        bindApi.updateAutoDistribute('', params).then(() => {
          state.visibleDistribute = false;
          message.success('操作成功');
          gridRef.value.refreshGrid();
        });
      },

      // 添加线索池到商机 弹窗按钮
      handleCancel() {
        state.visible = false;
        resetFields();
      },

      handleFilterOption(inputValue, option) {
        return option.key.leadPoolName.indexOf(inputValue) >= 0;
      },

      handleOk: () => {
        validate().then(() => {
          bindApi.addBind('', formPool.leadPool).then(() => {
            resetFields();
            state.visible = false;
            message.success('操作成功');
            gridRef.value.refreshGrid();
          });
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
//.iss-main {
//  max-height: calc(100vh - 120px);
//  overflow-y: auto;
//}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.score-level {
  padding: 2px 8px;
  color: #1a46f9;
  border-radius: 2px;
  border: 1px solid #1a46f9;
  margin-left: 3px;
}
//
.form-item /deep/ .ant-form-item-extra {
  font-size: 12px;
}
</style>
